import { MainPanelRoutes } from './mainpanel.router';
import { NgModule, ElementRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import {loadJquery} from '../../utility/loadjquery';

import {TopnavbarComponent} from './partials/topnavbar/topnavbar.component';
import {HomeComponent} from './components/home/home.component';
import {FooterComponent} from './partials/footerarea/footer.component';
import { AboutComponent } from './components/about/about.component';
import { ServiceComponent } from './components/service/service.component';
import { ContactComponent } from './components/contact/contact.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
@NgModule({
    declarations: [
        TopnavbarComponent,
        FooterComponent,
        HomeComponent,
        ServiceComponent,
        AboutComponent,
        ContactComponent,
        ErrorPageComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forChild(MainPanelRoutes),
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        TopnavbarComponent,
        FooterComponent,
        
    ],
    providers: [],
})
export class MainPanelModule {}