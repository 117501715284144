	<!-- =========Contact Image Area=========== -->
	<div class="contact-hero-banner mt-4">
	  <div class="contact-banner-text mt-4">
	    <h1>Contact US</h1>

	  </div>
	</div>
	<div class="contactus-area">
	  <div class="container">
	    <div class="single-contact-area">
	      <div class="row">
	        <!--single contact-->
	        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
	          <div class="single-contact-info">
	            <div class="info-icon">
	              <i class="far fa-envelope"></i>
	            </div>
	            <div class="info-content">
	              <h6>Mail address</h6>
	              <p> <a href="mailto:hello@xortonsoft.com">hello@xortonsoft.com</a></p>
	            </div>
	          </div>
	        </div>
	        <!--single contact-->
	        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 margin-top-sb-30">
	          <div class="single-contact-info">
	            <div class="info-icon">
	              <i class="fas fa-mobile-alt"></i>
	            </div>
	            <div class="info-content">
	              <h6>Our Phone</h6>
	              <p><a href="tel:+8801521212511">+88-01521-21-2511</a></p>
	            </div>
	          </div>
	        </div>
	        <!--single contact-->
	        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 margin-top-sb-30">
	          <div class="single-contact-info large-mb-d">
	            <div class="info-icon">
	              <i class="fas fa-map-marker-alt"></i>
	            </div>
	            <div class="info-content">
	              <h6>Our Location</h6>
	              <span>Dhaka, Bangladesh</span>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
	    <!--contact form area-->
	    <div class="row">
	      <div class="col-xl-12">
	        <div class="contact-form-area">
	          <!--contact left bg-->
	          <div class="contact-left-bg">
	            <img src="../../../../assets/img/contact/contact-p-2.png" alt="">
	          </div>
	          <div class="contact-form-heading">
	            <h3>Leave a Message</h3>
	          </div>
	          <div class="contact-form">
	            <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
	              <input type="text" placeholder="Enter your name" name="name" ngModel required #name="ngModel" />

	              <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger col-md-6">

	                <small *ngIf="name.errors.required">
	                  Name is required.
	                </small>


	              </div>

	              <br>
	              <input type="email" name="email" ngModel required #email="ngModel" placeholder="Enter your email"
	                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" />

	              <div *ngIf="email.invalid && (email.invalid || email.touched)" class="alert alert-danger col-md-6"
	                [hidden]="email.valid || email.untouched">
	                <small *ngIf="email.errors.required">Email is required</small>
	                <small *ngIf="email.errors.pattern">Please provide a valid email address</small>
	              </div>


	              <textarea placeholder="Write your message" type="message" name="messages" ngModel required
	                #messages="ngModel"></textarea>

	              <div *ngIf="messages.invalid && (messages.dirty || messages.touched)" class="alert alert-danger">

	                <small *ngIf="messages.errors.required">
	                  Message is required.
	                </small>


	              </div>

	              <!-- <div class="text-center">
                                <button class="btn btn-primary mt-3" value="Send" type="submit">Send Us</button>
							</div>     -->

	              <div class="send-btn">
	                <input type="submit" [disabled]="contactForm.invalid" value="send" class="btn btn-primary">
	              </div>


	            </form>
	          </div>
	          <!--contact right bg-->
	          <div class="contact-right-bg">
	            <img src="../../../../assets/img/contact/contact-p-1.png" alt="">
	          </div>
	        </div>
	      </div>
	    </div>
	  </div>
	</div>

	<!-- <div class="text-center mb-4">
        <h2>Test Area</h2>
        <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
            <input type="text" placeholder="Name" name="name" ngModel required #name="ngModel">
            <input type="text" placeholder="Email" email name="email" ngModel required #email="ngModel">
            <textarea placeholder="Messages" name="messages" ngModel required #messages="ngModel"></textarea>
            <input type="submit" value="Send">
        </form>
    </div> -->
