	<!-- =========Call to Action=========== -->
	<div class="callto-action">
		<div class="container">
			<div class="row">
				<div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
					<div class="callto-action-text">
						<h5>Like what you see? <span>Let’s work</span> </h5>
					</div>
				</div>
				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-5">
					<div class="callto-action-btn">
						<a [routerLink]="['/contact']">contact us</a>
					</div>
				</div>
			</div>
		</div>
    </div>

<!-- =========Footer Area=========== -->
<section id="footer-fixed">
	<div class="big-footer">
		<div class="container">
			<div class="row">
				<!--footer logo-->
				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
					<div class="footer-logo">
						<!-- <a href="#">
							<img src="../../../../assets/img/logo.png" alt="">
						</a> -->
						<h3 style="color:white"> <b style="font-weight: bold;color: #F05B24;">X</b>orton Soft</h3>
						<!-- <p> We have bothh premium and free website templates. Build your professional website with us.</p> -->
					</div>

					<br>
					<!--footer social-->
					<div class="social">
						<ul>
							<li><a class="footer-socials" href="http://facebook.com/xortonsoft" target="_blank"><i class="fab fa-facebook"></i></a></li>
							<li><a class="footer-socials" href="https://www.linkedin.com/company/xortonsoft" target="_blank"><i class="fab fa-linkedin"></i></a></li>
							<li><a class="footer-socials" href="https://twitter.com/xortonsoft" target="_blank"><i class="fab fa-twitter"></i></a></li>
							<!-- <li><a class="footer-socials" href="#"><i class="fab fa-youtube"></i></a></li> -->
						</ul>
					</div>
				</div>
				<!--footer quick links-->
				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
					<div class="footer-heading">
						<h3>quick links</h3>
					</div>
					<div class="footer-content">
						<ul>
							<li><a [routerLink]="['/about']" >about</a></li>
							<li><a [routerLink]="['/contact']">contact</a></li>
							<!--<li><a href="">privacy</a></li>
							 <li><a href="">our blog</a></li>
							<li><a href="">forum</a></li> -->
						</ul>
					</div>
				</div>
				<!--footer latest work-->
				<!-- <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
					<div class="footer-heading">
						<h3>Latest works</h3>
					</div>
					<div class="footer-content">
						<ul>
							<li><a href="">sparkel</a></li>
							<li><a href="">getparts</a></li>
							<li><a href="">youtuber</a></li>
							<li><a href="">smartco</a></li>
							<li><a href="">petcare</a></li>
						</ul>
					</div>
				</div> -->
				<!--footer subscribe-->
				<div class="col-xl-4 col-lg-4 col-md-4 col-sm-6">
					<div class="footer-heading">
						<h3>Get Updates</h3>
					</div>
					<div class="footer-content footer-cont-mar-40">
						<form (ngSubmit)="onSubmit(subscriptionForm)" #subscriptionForm="ngForm">
							<input id="leadgenaration" type="email" placeholder="Enter your email" required name="email" ngModel #email="ngModel"  
							pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" >
							
							<div *ngIf="email.invalid && (email.invalid || email.touched)" class="alert alert-danger col-md-6"
							[hidden]="email.valid || email.untouched">
							<small *ngIf="email.errors.required">Email is required</small>
							<small *ngIf="email.errors.pattern">Please provide a valid email address</small>
						  </div>
							<input id="subscribe" type="submit" value="Subscribe">
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--copyright-->
	<footer>
		<p>All rights reserved |  © Xorton Soft 2020</p>
	</footer>
</section>