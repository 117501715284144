
           <header id="home">
            <div class="main-navigation-1">
                <div class="container">
                    <div class="row">
                        <!-- logo-area-->
                        <div class="col-xl-2 col-lg-3 col-md-3">
                            <div class="logo-area">
                                <a [routerLink]="['/home']"><img src="../../../../assets/img/logo.png" alt="Xorton Soft"></a>
                            </div>
                        </div>
                        <!-- mainmenu-area-->
                        <div class="col-xl-10 col-lg-9 col-md-9">
                            <div class="main-menu f-right">
                                <nav id="mobile-menu">
                                    <ul>
                                        <li  >
                                            <a  [routerLink]="['/home']" routerLinkActive="current" >home</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/services']" routerLinkActive="current">Services</a>
                                        </li>
                                        <li>
                                            <a [routerLink]="['/about']" routerLinkActive="current" >about</a>
                                        </li>
                                       
                                        <!-- dropdown menu-area-->
                                        <!-- <li>
                                            <a href="#" onclick="return false">pages <i class="fas fa-angle-down"></i>
                                            </a>
                                            <ul class="dropdown">
                                                <li><a [routerLink]="['/about']"  routerLinkActive="current" >about us</a></li>
                                                <li><a href="portfolio.html">portfolio</a></li>
                                                <li><a href="portfolio2.html">portfolio two</a></li>
                                                <li><a href="single-portfolio.html">single portfolio</a></li>
                                                <li><a href="blog.html">blog page</a></li>
                                                <li><a href="single-blog.html">single blog</a></li>
                                                <li><a href="single-blog2.html">single blog two</a></li>
                                                <li><a href="team.html">our team</a></li>
                                                <li><a href="contact.html">contact us</a></li>
                                                <li><a href="404.html">404 Page</a></li>
                                            </ul>
                                        </li> -->
                                        <li>
                                            <a [routerLink]="['/contact']" routerLinkActive="current">contact</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <!-- mobile menu-->
                            <div class="mobile-menu"></div>
                            <!--Search-->
                            <!-- <div class="search-box-area">
                                <div id="search" class="fade">
                                    <a href="#" class="close-btn" id="close-search">
                                        <em class="fa fa-times"></em>
                                    </a>
                                    <input placeholder="what are you looking for?" id="searchbox" type="search" />
                                </div>
                                <div class="search-icon-area">
                                    <a href='#search'>
                                        <i class="fa fa-search"></i>
                                    </a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </header>