<!--  Preloader Start
========================-->
<!-- <div id="preloader">
  <div id="status">
    <img src="../../assets/img/loading.gif" alt="LOADING....!!!!!" />
  </div>
</div> -->

 
<app-topnavbar></app-topnavbar>
<router-outlet></router-outlet>
<app-footer></app-footer> 
