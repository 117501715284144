import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import * as $ from 'jquery';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
  })

  export class FooterComponent implements OnInit{
      constructor(private _toastr: ToastrService){}

      ngOnInit(){
          
      }

      // Dummy Subscription do it later

      onSubmit(subscriptionForm: NgForm) {
        if(subscriptionForm.valid){
          let email = subscriptionForm.value;
          subscriptionForm.reset();
          this._toastr.success("You have subscribed successfully.", "Confirmation",  { progressBar: true });
        }
        

      }
  }