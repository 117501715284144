


<div class="about-hero-banner">
    <div class="about-hero-text">
        <h1>Know more about Us</h1>
    
    </div>
</div>
    
    <!-- =========About Conetnt Area=========== -->
	<section id="about">
		<div class="abou-us-area">
			<div class="container">
				<div class="row">
					<div class="col-xl-6 col-lg-6 col-md-6">
						<!-- about brief-->
						<div class="about-content">
							<div class="about-section-title">
								<h4>What We do?</h4>
							</div>
							<p>We consistently provide the best solution for your business. Sometimes you met tremendous complexity to find out the automation solution for your problem whatever in any place such as Restaurant, Pharmacy, Retail, Delivery Management, Online Shop, Smart ERP, Garments, Industry, Smart Supply Chain, ETC. All solution has Artificial Intelligence-based future prediction capability on demand.</p>
							<!-- <div class="section-button">
								<a href="#">Request Quote</a>
							</div> -->
						</div>
					</div>
					<!-- about video-->
					<div class="col-xl-6 col-lg-6 col-md-6 margin-top-lb-30 margin-top-sb-30">
						
                            <div class="expertise-image">
                                <img src="../../../../assets/img/aboutUs/AboutUsImg2.jpg" alt="">
                            </div>
						
					</div>
				</div>
				<!-- about team photo-->
				<div class="row about-section-separate">
					<div class="col-xl-6 col-lg-6 col-md-6">
						<div class="about-team">
							<img src="../../../../assets/img/aboutUs/AboutUsImg4.gif" alt="">
						</div>
					</div>
					<!-- about brief-->
					<div class="col-xl-6 col-lg-6 col-md-6 margin-top-sb-30 margin-top-lb-30">
						<div class="about-content about-content-right">
							<div class="about-section-title ">
								<h4>Most Dedicated team we have</h4>
							</div>
							<p>Talented teams mean outstanding output and dedication bounce up <br> the quality of the product that always maintains our team. As a result, top-notch secure, scalable cloud based solution come up within project timeline.</p>
							<!-- <div class="section-button">
								<a href="#">Request Quote</a>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>