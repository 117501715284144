export const validationMsgCSS = {
    input: {
      isValid: 'is-valid',
      isInvalid: 'is-invalid'
    },
    div: {
      hasSuccess: 'has-success',
      hasDanger: 'has-danger'
    },
    alert: {
      warning: 'alert-warning',
      success: 'alert-success',
      danger: 'alert-danger',
      secondary: 'alert-secondary',
      primary: 'alert-primary',
      info: 'alert-info',
      light: 'alert-light'
    }
  }
  