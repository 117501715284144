	<!-- =========Portfolio Image Area=========== -->
	<div class="services-hero-banner">
		<div class="portfolio-hero-text">
			<h1>Highlights Our Services</h1>
			<!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent a viverra leo. </p> -->
		</div>
	</div>
	<div class="portfolio-main-area">
		<div class="container">
			<!--portfolio intro-->
			<!-- <div class="portfolio-intro">
				<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br> Praesent a viverra leo. Morbi purus augue, lacinia vel molestie.</p>
			</div> -->
			<div class="row">
				<!--single portfolio-->
				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 portfolio-headmove mb-4">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/smartres.png" alt="">
							<div class="portfolio-content">
								<p>Our sRMS provides a lot of rich user experiences such as a contactless order from a customer, smart dashboard for a business owner, real-time notification, and more.
									Everything comes with integrated POS. 
								</p>
								<a [routerLink]="['/contact']">Contact Us for more details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>Smart Restaurant Management System</h4>
					</div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 margin-top-sb-30 portfolio-headmove mb-4">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/webDev2.png" alt="">
							<div class="portfolio-content">
								<p>We provide any kind of customizable websites such as personal, blog, supply chain management system, enterprise resource planning, shop, entertainment, media, and any business.</p>
								<a [routerLink]="['/contact']">Contact Us for more details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>Website Development</h4>
					</div>
				</div>
				<!--single portfolio-->
				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 margin-top-sb-30 portfolio-headmove mb-4">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/CloudService.png" alt="">
							<div class="portfolio-content">
								<p>We provide cloud architecture solutions for your complex, enormous project.</p>
								<a [routerLink]="['/contact']">Contact Us for more details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>Cloud Based Solution</h4>
					</div>
				</div>
				<!--single portfolio-->
				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 default-margin-mt-sm margin-top-lb-30 margin-top-sb-30 portfolio-headmove mb-3">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/anyOnlineShop.png" alt="">
							<div class="portfolio-content">
								<p>Coming soon</p>
								<a [routerLink]="['/contact']">Contact Us for more details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>Any Online Shop Solution</h4>
					</div>
				</div>
				<!--single portfolio-->
				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 default-margin-mt-sm margin-top-lb-30 margin-top-sb-30 portfolio-headmove mb-3">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/MobileDevelopment.png" alt="">
							<div class="portfolio-content">
								<p>We provide cross-platform native android and Ios mobile application.</p>
								<a [routerLink]="['/contact']">view details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>Mobile Application</h4>
					</div>
				</div>
				<!--single portfolio-->
				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 default-margin-mt-sm margin-top-lb-30 margin-top-sb-30 portfolio-headmove mb-3">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/ManagementSystem.png" alt="">
							<div class="portfolio-content">
								<p>AI-based Management Solutions such as academic and education, donor management, fitness and health, child care, nutrition, construction, solar, insurance, property, etc.</p>
								<a [routerLink]="['/contact']">view details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>Management System</h4>
					</div>
				</div>
				<!--single portfolio-->
				<div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 default-margin-mt margin-top-lb-30 margin-top-sb-30 portfolio-headmove">
					<div class="single-portfolio">
						<div class="portfolio-image">
							<img src="../../../../assets/img/service/ECommerce.png" alt="">
							<div class="portfolio-content">
								<p>Launch your e-commerce business with Woo-Commerce CMS (Content Management System) backed by WordPress, you can easily choose from thousands of templates.</p>
								<a [routerLink]="['/contact']">view details</a>
							</div>
						</div>
					</div>
					<div class="portfolio-titile">
						<h4>E-commerce Development</h4>
					</div>
				</div>
				<!--single portfolio-->
			
			</div>
		</div>
		<!-- Pagination 
		<div class="col-xl-12">
			<div class="next-previous-page">
				<nav aria-label="...">
					<ul class="pagination">
						<li class="page-item disabled">
							<a class="page-link" href="#" tabindex="-1"> &#60; </a></li>
						<li class="page-item active"><a class="page-link" href="#">1 <span class="sr-only">(current)</span></a></li>
						<li class="page-item">
							<a class="page-link" href="#">2</a>
						</li>
						<li class="page-item"><a class="page-link" href="#">3</a></li>
						<li class="page-item">
							<a class="page-link" href="#">&#62;</a>
						</li>
					</ul>
				</nav>
			</div>
		</div> -->
	</div>
