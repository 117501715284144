import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from "@angular/router";
@Component({
    selector: 'app-topnavbar',
    templateUrl: './topnavbar.component.html',
    styleUrls: ['./topnavbar.component.css']
  })

  export class TopnavbarComponent implements OnInit{
    constructor(private elementRef: ElementRef,
        @Inject(DOCUMENT) private doc){}

      ngOnInit(){
        let link0: HTMLLinkElement = this.doc.createElement('link');
        link0.setAttribute('rel', 'stylesheet');
        link0.setAttribute('href', '../../../../assets/css/bootstrap.min.css');
        this.doc.head.appendChild(link0);
    
        let link1: HTMLLinkElement = this.doc.createElement('link');
        link1.setAttribute('rel', 'stylesheet');
        link1.setAttribute('href', '../../../../assets/css/meanmenu.css');
        this.doc.head.appendChild(link1);
    
        let link2: HTMLLinkElement = this.doc.createElement('link');
        link2.setAttribute('rel', 'stylesheet');
        link2.setAttribute('href', '../../../../assets/css/magnific-popup.min.css');
        this.doc.head.appendChild(link2);
    
        let link3: HTMLLinkElement = this.doc.createElement('link');
        link3.setAttribute('rel', 'stylesheet');
        link3.setAttribute('href', '../../../../assets/css/aos.min.css');
        this.doc.head.appendChild(link3);
    
        let link4: HTMLLinkElement = this.doc.createElement('link');
        link4.setAttribute('rel', 'stylesheet');
        link4.setAttribute('href', '../../../../assets/css/slick.css');
        this.doc.head.appendChild(link4);
    
        let link5: HTMLLinkElement = this.doc.createElement('link');
        link5.setAttribute('rel', 'stylesheet');
        link5.setAttribute('href', '../../../../assets/style.css');
        this.doc.head.appendChild(link5);
    
        let link6: HTMLLinkElement = this.doc.createElement('link');
        link6.setAttribute('rel', 'stylesheet');
        link6.setAttribute('href', '../../../../assets/css/responsive.css');
        this.doc.head.appendChild(link6);
    
    
        var s01 = document.createElement("script");
        s01.type = "text/javascript";
        s01.src = "../../../../assets/js/vendor/jquery-2.2.4.min.js";
        this.elementRef.nativeElement.appendChild(s01);
    
        var s02 = document.createElement("script");
        s02.type = "text/javascript";
        s02.src = "../../../../assets/js/popper.min.js";
        this.elementRef.nativeElement.appendChild(s02);
    
        var s03 = document.createElement("script");
        s03.type = "text/javascript";
        s03.src = "../../../../assets/js/bootstrap.min.js";
        this.elementRef.nativeElement.appendChild(s03);
    
        var s04 = document.createElement("script");
        s04.type = "text/javascript";
        s04.src = "../..//../../assets/js/magnific-popup.min.js";
        this.elementRef.nativeElement.appendChild(s04);
    
        var s05 = document.createElement("script");
        s05.type = "text/javascript";
        s05.src = "../../../../assets/js/waypoints.min.js";
        this.elementRef.nativeElement.appendChild(s05);
    
        var s06 = document.createElement("script");
        s06.type = "text/javascript";
        s06.src = "../../../../assets/js/counterup.min.js";
        this.elementRef.nativeElement.appendChild(s06);
    
        var s07 = document.createElement("script");
        s07.type = "text/javascript";
        s07.src = "../../../../assets/js/meanmenu.min.js";
        this.elementRef.nativeElement.appendChild(s07);
    
        
        var s08 = document.createElement("script");
        s08.type = "text/javascript";
        s08.src = "../../../../assets/js/aos.min.js";
        this.elementRef.nativeElement.appendChild(s08);
    
        var s09 = document.createElement("script");
        s09.type = "text/javascript";
        s09.src = "../../../../assets/js/isotope.min.js";
        this.elementRef.nativeElement.appendChild(s09);
    
        var s10 = document.createElement("script");
        s10.type = "text/javascript";
        s10.src = "../../../../assets/js/jquery.backgroundMove.js";
        this.elementRef.nativeElement.appendChild(s10);
    
        var s11 = document.createElement("script");
        s11.type = "text/javascript";
        s11.src = "../../../../assets/js/slick.min.js";
        this.elementRef.nativeElement.appendChild(s11);
    
        var s12 = document.createElement("script");
        s12.type = "text/javascript";
        s12.src = "../../../../assets/js/scrollUp.js";
        this.elementRef.nativeElement.appendChild(s12);
    
        var s13 = document.createElement("script");
        s13.type = "text/javascript";
        s13.src = "../../../../assets/js/main.js";
        this.elementRef.nativeElement.appendChild(s13);
      }
  }