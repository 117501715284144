import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { validationMsgCSS } from '../../utility/validationMsgCSS';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  constructor() {
  }
  validateForm(abstractControl: AbstractControl, key: any, formErrors: {}, validationMessages: {}): void {
    formErrors[key] = '';
    const element = document.getElementById(key);
    if (abstractControl && !abstractControl.valid && (abstractControl.touched || abstractControl.dirty)) {
      const message = validationMessages[key];
      const parentElement = element.parentElement;
      for (const errorKey in abstractControl.errors) {
        formErrors[key] += message[errorKey] + ' ';
      }
      if (parentElement.classList.contains(validationMsgCSS.div.hasSuccess)) {
        parentElement.classList.remove(validationMsgCSS.div.hasSuccess);
      }
      if (!parentElement.classList.contains(validationMsgCSS.div.hasDanger)) {
        parentElement.classList.contains(validationMsgCSS.div.hasDanger);
      }
      if (element.classList.contains(validationMsgCSS.input.isValid)) {
        element.classList.remove(validationMsgCSS.input.isValid);
      }
      if (!element.classList.contains(validationMsgCSS.input.isInvalid)) {
        element.classList.add(validationMsgCSS.input.isInvalid);
      }
    }
    else if (abstractControl && abstractControl.valid) {
      const parentElement = element.parentElement;
      if (parentElement.classList.contains(validationMsgCSS.div.hasDanger)) {
        parentElement.classList.remove(validationMsgCSS.div.hasDanger);
      }
      if (!parentElement.classList.contains(validationMsgCSS.div.hasSuccess)) {
        parentElement.classList.contains(validationMsgCSS.div.hasSuccess);
      }
      if (element.classList.contains(validationMsgCSS.input.isInvalid)) {
        element.classList.remove(validationMsgCSS.input.isInvalid);
      }
      if (!element.classList.contains(validationMsgCSS.input.isValid)) {
        element.classList.add(validationMsgCSS.input.isValid);
      }
    }
  }
}