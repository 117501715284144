	<!-- =========404 Image area=========== -->
	<div class="error-page-area mt-4">
		<div class="container m-5">
			<div class="row">
				<!--404 text image-->
				<div class="col-xl-12">
					<div class="error-text mt-5">
					<img src="../../../../assets/img/ErrorIcon.png" alt="Error Page">
						<h2>sorry we can't found anything</h2>
						<a  [routerLink]="['/home']" >back to home</a>
					</div>
				</div>
			</div>
		</div>
	</div>