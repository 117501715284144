import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ElementRef } from '@angular/core';
import { RouterModule,  } from '@angular/router';
import { routes } from './app.router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


import { AppComponent } from './app.component';
import { MainpanelComponent } from './mainpanel/mainpanel.component';
import { MainPanelModule } from './mainpanel/mainpanel.module';
import {loadJquery} from '../utility/loadjquery';
import {ToastrModule} from "ngx-toastr";

import { HttpClientModule }    from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    MainpanelComponent,
    
  ],
  imports: [
    BrowserModule,
  //  RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
}),
    MainPanelModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
