import {HomeComponent} from './components/home/home.component';
import {AboutComponent} from './components/about/about.component';
import {MainpanelComponent} from './mainpanel.component';
import { ServiceComponent } from './components/service/service.component';
import { ContactComponent } from './components/contact/contact.component';
import {ErrorPageComponent} from './components/error-page/error-page.component';
import { Route } from '@angular/router';



export const MainPanelRoutes: Route[] = [
    {
        path: '',
        component: MainpanelComponent,
        children: [
            {
                path: '', redirectTo: 'home', pathMatch: 'full'
            },
            {
                path: 'home', component: HomeComponent
            },
            {
                path:'about', component: AboutComponent
            },
            {
                path:'services', component: ServiceComponent
            },
            {
                path:'contact', component: ContactComponent
            },
            {
                path: '**', component: ErrorPageComponent
            }
        ]
    }
           
];
